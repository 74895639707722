html {
  background-color: rgb(202, 230, 215);
}

body {
  font-family: "EB Garamond", serif;
  font-size: "16px";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar-item {

  font-weight: 500;
  background-color: transparent !important;
  color: #555;
}

.navbar-item:hover {
  color: black;
  text-decoration: underline;
}
